import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { MenuItem, Nav, Navbar, NavDropdown } from "react-bootstrap"
import logo from "../images/logoV2.png"
import { Link } from "gatsby"
import StrataDocumentsModal from "../pages/documents/strata-documents-modal"

const Header = () => {
  const [expanded, setExpanded] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function HeaderStyle() {
    if(scrollPosition > 70) {
      return {
        'marginBottom': '0px',
        'position': 'sticky',
        'top': '0px',
        'background': 'rgba(255, 255, 255, 0.8)'
      };
    } else {
      return {
        "marginBottom": "0px",
        "position": "sticky",
        "top": "0px",
        'background': 'rgba(255, 255, 255, 1)'
      };
    }
  }
  function handleOpenDocument(form) {
    setCurrentForm(form);
    setExpanded(false);
  }

  const strataPortalUrl = `${process.env.GATSBY_API_URL}/strataLogin`
  const residentialPortalUrl = `${process.env.GATSBY_API_URL}/login`
  const strataManagementLoginNew = 'https://korecki.securecafe.com/residentservices/korecki-condocafe/userlogin.aspx'
  const [currentForm, setCurrentForm] = useState("");

  const developerServices = 'developerServices'
  const rentalManagementProposalRequest = 'rentalManagementProposalRequest'
  const strataProposalRequest = 'strataProposalRequest'

  const developerServicesUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/17'
  const rentalManagementProposalRequestUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/11'
  const strataProposalRequestUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/10'

  return (
    <div>
      <Navbar collapseOnSelect expanded={expanded} style={HeaderStyle()}>
        <Navbar.Header>
          <Link to="/">
            <Navbar.Brand>
              <img src={logo} className="logo" alt="Korecki Real Estate Services" />
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")}/>
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav pullRight>
            <MenuItem eventKey={1} href="/">
              Home
            </MenuItem>

            <NavDropdown eventKey={2} title="Strata" id="strata-nav-dropdown">
              <MenuItem eventKey={2.1} href='/strata-management-services'>
                Strata Management Services
              </MenuItem>
              <MenuItem href="#" eventKey={2.2} onClick={() => handleOpenDocument(strataProposalRequest)}>
                Strata Management Proposal Request
                <StrataDocumentsModal
                  open={currentForm===strataProposalRequest}
                  openModal={setCurrentForm}
                  documentUrl={strataProposalRequestUrl}/>
              </MenuItem>
              <NavDropdown eventKey={2.3} title="Areas We Serve" id="basic-nav-dropdown">
                <MenuItem eventKey={3.8} href="/strata-management-services/areas-we-serve-strata-management-services/burnaby-strata-management-services/">Burnaby</MenuItem>
                <MenuItem eventKey={3.9} href="/strata-management-services/areas-we-serve-strata-management-services/coquitlam-strata-management-services/">Coquitlam</MenuItem>
                <MenuItem eventKey={3.10} href="/strata-management-services/areas-we-serve-strata-management-services/delta-strata-management-services/">Delta</MenuItem>
                <MenuItem eventKey={3.11} href="/strata-management-services/areas-we-serve-strata-management-services/new-westminster-strata-management-services/">New Westminster</MenuItem>
                <MenuItem eventKey={3.12} href="/strata-management-services/areas-we-serve-strata-management-services/north-vancouver-strata-management-services/">North Vancouver</MenuItem>
                <MenuItem eventKey={3.7} href="/strata-management-services/areas-we-serve-strata-management-services/port-moody-strata-management-services/">Port Moody</MenuItem>
                <MenuItem eventKey={3.5} href="/strata-management-services/areas-we-serve-strata-management-services/richmond-strata-management-services/">Richmond</MenuItem>
                <MenuItem eventKey={3.6} href="/strata-management-services/areas-we-serve-strata-management-services/surrey-strata-management-services/">Surrey</MenuItem>
                <MenuItem eventKey={3.4} href="/strata-management-services/areas-we-serve-strata-management-services/vancouver-strata-management-services/">Vancouver</MenuItem>
                <MenuItem eventKey={3.13} href="/strata-management-services/areas-we-serve-strata-management-services/west-vancouver-strata-management-services/">West Vancouver</MenuItem>
              </NavDropdown>
              {/*<MenuItem eventKey={2.3} href='/strata-management-services/documents'>
              Strata Documents
            </MenuItem>*/}
            </NavDropdown>

            <NavDropdown eventKey={3} title="Rental" id="residential-nav-dropdown">
              <MenuItem eventKey={3.1} href='/rental-management-services'>
                Rental Management Services
              </MenuItem>
              <MenuItem href="#" eventKey={3.2} onClick={() => handleOpenDocument(rentalManagementProposalRequest)}>
                Rental Management Proposal Request
                <StrataDocumentsModal
                  open={currentForm===rentalManagementProposalRequest}
                  openModal={setCurrentForm}
                  documentUrl={rentalManagementProposalRequestUrl}/>
              </MenuItem>
              <NavDropdown eventKey={3.3} title="Areas We Serve" id="basic-nav-dropdown">
                <MenuItem eventKey={3.8} href="/rental-management-services/areas-we-serve-rental-management-services/burnaby-rental-management-services/">Burnaby</MenuItem>
                <MenuItem eventKey={3.9} href="/rental-management-services/areas-we-serve-rental-management-services/coquitlam-rental-management-services/">Coquitlam</MenuItem>
                <MenuItem eventKey={3.10} href="/rental-management-services/areas-we-serve-rental-management-services/delta-rental-management-services/">Delta</MenuItem>
                <MenuItem eventKey={3.11} href="/rental-management-services/areas-we-serve-rental-management-services/new-westminster-rental-management-services/">New Westminster</MenuItem>
                <MenuItem eventKey={3.12} href="/rental-management-services/areas-we-serve-rental-management-services/north-vancouver-rental-management-services/">North Vancouver</MenuItem>
                <MenuItem eventKey={3.7} href="/rental-management-services/areas-we-serve-rental-management-services/port-moody-rental-management-services/">Port Moody</MenuItem>
                <MenuItem eventKey={3.5} href="/rental-management-services/areas-we-serve-rental-management-services/richmond-rental-management-services/">Richmond</MenuItem>
                <MenuItem eventKey={3.6} href="/rental-management-services/areas-we-serve-rental-management-services/surrey-rental-management-services/">Surrey</MenuItem>
                <MenuItem eventKey={3.4} href="/rental-management-services/areas-we-serve-rental-management-services/vancouver-rental-management-services/">Vancouver</MenuItem>
                <MenuItem eventKey={3.13} href="/rental-management-services/areas-we-serve-rental-management-services/west-vancouver-rental-management-services/">West Vancouver</MenuItem>
              </NavDropdown>
            </NavDropdown>

            <NavDropdown eventKey={4} title="Developers" id="basic-nav-dropdown">
              <MenuItem eventKey={4.1} href="/developers"> Developer Services </MenuItem>
              <MenuItem href="#" eventKey={4.2} onClick={() => handleOpenDocument(developerServices)}>
                Developer Services Proposal Request
                <StrataDocumentsModal
                  open={currentForm===developerServices}
                  openModal={setCurrentForm}
                  documentUrl={developerServicesUrl}/>
              </MenuItem>
            </NavDropdown>

            <NavDropdown eventKey={9} title="Documents" id="documents-nav-dropdown">
              <MenuItem eventKey={9.1} href="/documents/strata-management-documents">Strata Management Documents</MenuItem>
              <MenuItem eventKey={9.3} href="/documents/rental-management-documents">Rental Management Documents</MenuItem>
              <MenuItem eventKey={9.2} href="/documents/proposal-requests">Proposal Requests</MenuItem>
            </NavDropdown>

            <MenuItem eventKey={4} href='/listings'>
              Listings
            </MenuItem>

            <NavDropdown eventKey={5} title="About" id="about-nav-dropdown">
              <MenuItem eventKey={5.1} href='/company'>
                Corporate Profile
              </MenuItem>
              <MenuItem eventKey={5.2} href='/careers'>
                Careers
              </MenuItem>
            </NavDropdown>
            <MenuItem eventKey={7} href='/contact'>
              Contact
            </MenuItem>

            <NavDropdown eventKey={8} title="Login" id="login-nav-dropdown">
              <MenuItem eventKey={8.1} href={strataPortalUrl}>
                Strata Management Login
              </MenuItem>
              <MenuItem eventKey={8.2} href={residentialPortalUrl}>
                Rental Management Login
              </MenuItem>
              <MenuItem eventKey={8.3} href={strataManagementLoginNew}>
                Strata Management Login (Condo Cafe)
              </MenuItem>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
